<template>
	<div class="study">
		<w-navTab titleText="线上培训"></w-navTab>
		<loading-page :loading="loadingPage"></loading-page>
		<vant-list class="list-view" :loading="loading" :finished="finished" :is-empty="isEmpty" @onLoad="onLoad">
			<div class="head-picture"></div>
			<div class="head-title">
				<scroll-tab :menu="navList" :active="active" @click="onTab"></scroll-tab>
			</div>
			<div class="middle">
				<div class="middle-item" v-for="item in studyList"
					@click="$router.push({ name: 'StudyDetail', params: { id: item.id } })">
					<div class="middle-item-top">
						<img :src="item.thumb" alt="" />
						<div class="middle-item-top-btn">
							<van-icon name="play" color="#3377FF" />
						</div>
					</div>
					<div class="middle-item-bottom">
						<div class="item-bottom-title">{{ item.title }}</div>
						<div class="item-bottom-footer">
							<div class="footer-text">{{ item.create_time }}</div>
							<div class="footer-right">
								<div class="footer-right-box">
									<div class="footer-right-box-picture">
										<img src="@/assets/img/icon200.png" alt="" />
									</div>
									<div class="footer-text">{{ item.approval }}</div>
								</div>
								<div class="footer-right-box">
									<div class="footer-right-box-picture">
										<img src="@/assets/img/icon201.png" alt="" />
									</div>
									<div class="footer-text">{{ item.views }}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</vant-list>
	</div>
</template>

<script>
import ScrollTab from '@/components/ScrollTab';
import StudyApi from '@/api/study';
import VantList from '@/components/VantList';
import keepMiXin from '@/mixins/keep';
import scrollMiXin from '@/mixins/scroll';
import LoadingPage from '@/components/LoadingPage';

const keep = keepMiXin(['StudyDetail']);
const scroll = scrollMiXin(['.list-view']);

export default {
	name: 'Study',
	mixins: [scroll, keep],
	data() {
		return {
			active: 0,
			navList: [],
			studyList: [],
			loading: true,
			finished: false,
			isEmpty: false,
			loadingPage: true,
			paramsPage: 1,
			paramsLimit: 10,
			paramsGroupId: 0
		};
	},
	created() {
		StudyApi.getGroup()
			.then(result => {
				let navList = [];

				result.data.forEach((item, key) => {
					navList.push({
						id: item.id,
						title: item.name
					});
				});

				this.navList = navList;
				this.paramsGroupId = this.navList[0].id;
				this.onLoad();
			})
			.catch(error => {
				console.log(error);
			});
	},
	methods: {
		onTab(index) {
			this.active = index;
			this.studyList = [];
			this.paramsGroupId = this.navList[index].id;
			this.paramsPage = 1;
			this.finished = false;
			this.isEmpty = false;
			this.onLoad();
		},
		onLoad() {
			this.loading = true;

			let params = {
				group_id: this.paramsGroupId,
				page: this.paramsPage,
				limit: this.paramsLimit
			};

			StudyApi.getList(params)
				.then(result => {
					this.paramsPage++;
					this.studyList = this.studyList.concat(result.data.list);

					this.loading = false;
					this.loadingPage = false;

					if (this.studyList.length <= 0) {
						this.isEmpty = true;
					}

					if (this.studyList.length >= result.data.total) {
						this.finished = true;
					}
				})
				.catch(error => {
					console.log(error);
				});
		}
	},
	components: {
		VantList,
		ScrollTab,
		LoadingPage
	}
};
</script>

<style scoped lang="less">
.study {
	min-height: 100vh;

	.head-picture {
		width: 100%;
		height: 200px;
		background: url(../../assets/img/test252.png);
		background-size: 100% 100%;
	}

	.head-title {
		width: 100%;
		padding: 15px 10px 0 10px;
		background: #f2f2f2;
		border-radius: 15px 15px 0px 0px;
		margin-top: -15px;
		word-break: keep-all;
		overflow-x: auto;
		box-sizing: border-box;
	}

	.middle {
		padding: 0 10px 10px 10px;
		box-sizing: border-box;

		.middle-item {
			background-color: #fff;
			border-radius: 6px;
			overflow: hidden;
			margin-top: 10px;
		}

		.middle-item-top {
			position: relative;
			width: 355px;
			height: 200px;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}

			.middle-item-top-btn {
				position: absolute;
				left: 10px;
				bottom: 10px;
				width: 50px;
				height: 50px;
				background: rgba(255, 255, 255, 0.8);
				border-radius: 100px;
				text-align: center;
				padding: 10px;
				box-sizing: border-box;

				.van-icon {
					font-size: 30px;
				}
			}
		}

		.middle-item-bottom {
			padding: 10px;

			.item-bottom-title {
				font-size: 18px;
				line-height: 24px;
				color: #333;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				overflow: hidden;
			}

			.item-bottom-footer {
				padding-top: 10px;
				display: flex;
				justify-content: space-between;
				align-items: center;

				.footer-text {
					font-size: 14px;
					line-height: 16px;
					color: #666;
				}

				.footer-right {
					display: flex;
					justify-content: space-between;
					align-items: center;

					.footer-right-box {
						display: flex;
						justify-content: space-between;
						align-items: center;
						margin-right: 8px;

						.footer-right-box-picture {
							width: 16px;
							min-width: 16px;
							height: 16px;
							margin-right: 2px;

							img {
								width: 100%;
								height: 100%;
								object-fit: cover;
							}
						}
					}

					.footer-right-box:last-child {
						margin: 0;
					}
				}
			}
		}
	}
}
</style>
