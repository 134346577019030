import Config from "@/vendor/config"
import Request from "@/api/request.js"

// 获取详情
const getGroup = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/study/groups', params)
}

// 获取列表
const getList = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/study/list', params)
}

const studyDetail = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/study/detail', params)
}


export default {
	getGroup,
	getList,
	studyDetail
}
